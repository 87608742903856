$next-color: #C904A2;
$back-color: #513295;
$next-color-hover: #aa0774;
$back-color-hover: #310d7e;
$title-color: #FFE581;
$content-color: #032E26;
$title-black: #032E26;
$tryagain-color: #FE6349;
$tryagain-color-hover: #fa3e1c;
$correct-color: #AAF490;
$correct-color-hover: #5dd431;