.nav-top {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    justify-content: space-between;
    padding-top: 34px;
    padding-bottom: 30px;
    z-index: 999;
    .right-header, .left-header {
        display: flex;
        align-items: center;
    }
}
.title-header {
    font-family: 'Filson Soft';
    font-size: 20px;
    line-height: 1;
    color: #fff;
    padding-left: 20px;
    position: relative;
    margin-left: 20px;
    &:before {
        position: absolute;
        left: 0;
        top: -4px;
        width: 1px;
        height: 26px;
        content: "";
        background-color: #fff;
    }
}
.img-useway {
    width: 48px;
}
.right-header {
    .icon-volume {
        color: #fff;
        margin-right: 98px;
        font-size: 45px;
    }
}
.logo-header {
    cursor: pointer;
}