// tailwind.scss
@import '~bootstrap/dist/css/bootstrap.css';
@import "../src/assets/variables.scss";
@import "~tailwindcss/base";
@import "~tailwindcss/components";
@import "~tailwindcss/utilities";
@import "../src/assets/fonts/stylesheet.css";
@import "../src/assets/icomoon/style.css";
@import "../src/assets/common.scss";
@import "../src/assets/loading.scss";
@import "../src/assets/rotate.scss";
@import "../src/assets/header.scss";
@import "../src/assets/private.scss";
