.block-loading {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 9999999990;
    span {
        position: absolute;
        left: 50%;
        top: calc(50% - 40px);
        transform: translate(-50%, -50%);
        font-family: 'Filson Soft';
        font-size: 38px;
        line-height: 1;
        text-align: center;
        color: #fff;
    }
}
.progress{
    height: 24px;
    border: 1px solid #A5A5A5;
    border-radius: 15px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 385px;
    .color{
        position: absolute;
        background-color: $title-color;
        width: 0px;
        height: 22px;
        border-radius: 15px;
        animation: progres 4s linear;
    }
}
@keyframes progres{
    0%{
      width: 0%;
    }
    25%{
        width: 50%;
    }
    50%{
        width: 75%;
    }
    75%{
        width: 85%;
    }
    100%{
        width: 100%;
    }
};