.main-container {
  position: relative;
  overflow: hidden;
}
.game-container {
  padding-left: 47px;
  padding-right: 47px;
}
.fullscreen {
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
  padding-top: 105px;
  &.bg-game-three {
    background-image: url("./images/bg-game1.png");
    background-color: #042e26;
  }
  &.bg-game-ready {
    background-image: url("./images/ready-bg.png");
    justify-content: unset;
    align-items: center;
    min-height: 100vh;
  }
  &.bg-game-orbit-ready {
    background-image: url("../../public/game-orbit/common/ufo-background@2x.png");
    justify-content: unset;
    align-items: center;
    position: relative;
  }
  &.bg-game-feeling-ready {
    background-image: url("../../public/game-orbit/common/feeling-bg-getstart@2x.png");
    justify-content: unset;
    align-items: center;
    position: relative;
  }
  &.bg-game-play {
    background-image: url("./images/play-game.png");
    justify-content: unset;
    align-items: center;
    position: relative;
  }
  &.hidden-layout {
    position: absolute;
    right: 100%;
    top: 0;
    z-index: -1000;
    opacity: 0;
    &.active-layout {
      opacity: 1;
      right: 0;
      z-index: 1;
      position: relative;
    }
  }
  &.bg-game-rotate {
    background-image: url("./images/rotate-bg.png");
  }
  &.bg-game-orbit-rotate {
    background-image: url("../../public/game-orbit/common/bg-orbit-rotate@2x.png");
  }
  &.bg-game-feeling-rotate {
    background-image: url("../../public/game-orbit/common/bg-game-feeling-rotate@2x.png");
  }
  &.bg-game-mobile {
    background-image: url("./images/mobile-bg.png");
    padding: 0;
    background-position: center bottom;
    min-height: unset;
    top: 0;
    bottom: 0;
    display: block;
    position: absolute !important;
    height: 100%;
  }
}

.container-slider {
  max-width: 69%;
  margin: 0 auto;
  position: unset;
  flex: 1;
}
.title-block {
  font-family: "Filson Soft";
  font-size: 28px;
  line-height: 32px;
  text-align: center;
  margin-bottom: 16px;
  display: block;
  color: #fff;
  &__default {
    color: #fff;
  }
  &__primary {
    color: $title-color;
  }
  &__black {
    color: $title-black;
  }
  &-custom {
    &__top {
      margin-top: 60px;
    }
  }
  &__swipe {
    font-weight: 800;
    font-size: 24px;
    line-height: calc(28 / 24);
    display: inline-block;
    position: relative;
    margin-bottom: 0;
    &:before {
      position: absolute;
      left: calc(100% + 30px);
      top: 0;
      content: "";
      width: 82px;
      background-size: contain;
      background-repeat: no-repeat;
      height: 30px;
      background-image: url("./images/right-swipe.svg");
    }
    &:after {
      position: absolute;
      right: calc(100% + 30px);
      top: 0;
      content: "";
      width: 82px;
      background-size: contain;
      background-repeat: no-repeat;
      height: 30px;
      background-image: url("./images/left-swipe.svg");
    }
  }
}
.item-intro-slider {
  text-align: center;
  position: absolute;
  visibility: hidden;
  z-index: 0;
  display: none;
  opacity: 0;
  &.active-slider {
    visibility: visible;
    position: unset;
    z-index: 1;
    display: block;
    opacity: 1;
  }
  .image-introduction {
    margin: auto;
    margin-bottom: 30px;
    display: inline-block;
    border-radius: 30px;
    border-width: 4px 4px 10px 4px;
    max-height: 42vh;
  }
  &__dot {
    margin: auto;
    display: inline-block;
  }
  .content-introduction {
    font-family: "Roboto";
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: #fff;
    margin-bottom: 36px;
  }
}
.box-dot {
  display: flex;
  justify-content: center;
  max-width: 550px;
  margin: auto;
  margin-bottom: 30px;
  width: calc(100% + 20px);
  margin-left: -10px;
  margin-right: -10px;
  span {
    background: #ffe581;
    margin: 6px;
    opacity: 0.1;
    border-radius: 37px;
    width: 170px;
    height: 10px;
    border-radius: 37px;
    &.active-dot {
      opacity: 1;
    }
  }
  .item-dot__1,
  .item-dot__2,
  .item-dot__3 {
    width: 30%;
  }
  .item-dot__4 {
    width: 25%;
  }
  .item-dot__5 {
    width: 20%;
  }
}

.fullscreen-not-scroll {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 105px;
  width: 100%;
  position: relative;
  box-sizing: border-box;
}

.box-input-name {
  width: 62%;
  margin: auto;
  border-radius: 147px;
  overflow: hidden;
  input {
    width: 100%;
    height: 60px;
    font-family: "Filson Soft";
    font-weight: 800;
    font-size: 38px;
    line-height: 1;
    text-align: center;
    outline: none !important;
  }
}
.box-ufo-ready {
  margin-top: -35px;
}
.img-rotate {
  display: block;
  width: 227px;
  height: 168px;
  margin: 0 auto;
  transform: translateY(-350px);
  animation: flying 10s cubic-bezier(0.165, 0.84, 0.44, 1) 1s;
  z-index: 1;
  position: relative;

  @keyframes flying {
    0% {
      transform: translateY(-350px);
    }
    20% {
      transform: translateY(0px);
    }
    60% {
      transform: translateY(0px);
    }
    80% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(0);
    }
  }
}
.trapezoid {
  width: 228px;
  height: 50vh;
  margin-left: 20px;
  margin-top: -10px;
  -webkit-clip-path: polygon(35% 0%, 62% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(35% 0%, 62% 0%, 100% 100%, 0% 100%);
  overflow: hidden;
  position: relative;
  max-height: 50vh;

  &-orbit {
    width: 228px;
    height: 50vh;
    margin-left: 20px;
    margin-top: -10px;
    -webkit-clip-path: polygon(35% 0%, 62% 0%, 100% 100%, 0% 100%);
    clip-path: polygon(35% 0%, 62% 0%, 100% 100%, 0% 100%);
    overflow: hidden;
    position: relative;
    max-height: 50vh;
  }

  &-feeling {
    width: 228px;
    height: 50vh;
    margin-left: 20px;
    margin-top: -10px;
    -webkit-clip-path: polygon(35% 0%, 62% 0%, 100% 100%, 0% 100%);
    clip-path: polygon(35% 0%, 62% 0%, 100% 100%, 0% 100%);
    overflow: hidden;
    position: relative;
    max-height: 50vh;
  }

  &-orbit::before {
    content: "";
    left: 0;
    top: 0;
    background: linear-gradient(
      180deg,
      rgba(81, 50, 149, 0.5) -0.25%,
      rgba(81, 50, 149, 0) 100%
    );
    position: absolute;
    width: 100%;
    height: 0;
    animation: render 10s cubic-bezier(0.165, 0.84, 0.44, 1) 3s;
    animation-delay: 3s;
  }

  &-feeling::before {
    content: "";
    left: 0;
    top: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 194, 255, 0.5) -0.25%,
      rgba(0, 194, 255, 0) 100%
    );
    position: absolute;
    width: 100%;
    height: 0;
    animation: render 10s cubic-bezier(0.165, 0.84, 0.44, 1) 3s;
    animation-delay: 3s;
  }

  &::before {
    content: "";
    left: 0;
    top: 0;
    background: linear-gradient(
      180deg,
      rgba(25, 226, 189, 0.5) -0.25%,
      rgba(25, 226, 189, 0) 100%
    );
    position: absolute;
    width: 100%;
    height: 0;
    animation: render 10s cubic-bezier(0.165, 0.84, 0.44, 1) 3s;
    animation-delay: 3s;
  }
  @keyframes render {
    0% {
      height: 0;
    }
    20% {
      height: 50vh;
    }
    60% {
      height: 50vh;
    }
    80% {
      height: 50vh;
    }
    100% {
      height: 50vh;
    }
  }
}

.count-down__ready {
  font-family: "Filson Soft";
  font-style: normal;
  font-weight: 800;
  font-size: 152px;
  line-height: 199px;
  text-align: center;
  position: absolute;
  z-index: 2;
  top: 50%;
  color: #fff;
  left: 50%;
  transform: translate(-50%, -50%);
  animation-delay: 4s;
}

.choose-game {
  max-width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  &__item {
    width: 50%;
    max-width: 400px;
    background: #fff;
    border-radius: 30px;
    margin: 10px;
    padding: 20px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &:before {
      position: absolute;
      content: "";
      width: 150%;
      height: 100%;
      border-radius: 50% 50% 0 0;
      background-color: rgb(255, 242, 193, 1);
      left: 50%;
      top: 150%;
      transform: translateX(-50%);
      z-index: 0;
      transition: top 0.15s ease-in-out;
    }
    &--content {
      margin: 40px 0 16px;
      padding: 0 10px;
      position: relative;
      z-index: 1;
    }
    &--image {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 280px;
      position: relative;
      z-index: 1;
      img {
        visibility: hidden;
      }
    }
    &:hover {
      &:before {
        top: 30%;
      }
    }
  }
}
.title-item-choose {
  font-family: "Filson Soft";
  font-size: 24px;
  line-height: calc(28 / 24);
  text-align: center;
  margin-bottom: 20px;
}
.content-item-choose {
  font-family: "Roboto";
  font-size: 22px;
  line-height: calc(26 / 22);
  text-align: center;
  color: $content-color;
}
.private-item {
  position: absolute;
  left: -155px;
  top: 50%;
  transform: translateY(-50%);
  &.private-item-v2 {
    left: 5%;
    .box-result-item__img {
      width: 200px;
      height: 160px;
      overflow: hidden;
      border: 4px solid #ffffff;
      box-sizing: border-box;
      border-radius: 20px;
      background-size: cover;
      background-position: center;
      img {
        visibility: hidden;
      }
    }
  }
}
.public-item {
  position: absolute;
  right: -155px;
  top: 50%;
  transform: translateY(-50%);
  &.public-item-v2 {
    right: 5%;
    .box-result-item__img {
      width: 200px;
      height: 160px;
      overflow: hidden;
      border: 4px solid #ffffff;
      box-sizing: border-box;
      border-radius: 20px;
      background-size: cover;
      background-position: center;
      img {
        visibility: hidden;
      }
    }
  }
}

.box-drag-default {
  padding: 20px;
  background-color: #fff;
  border: 4px solid $title-black;
  box-sizing: border-box;
  box-shadow: 0px 8px 0px $title-black;
  border-radius: 30px;
  width: 48.6vw;
  position: relative;
  overflow: hidden;
  max-width: 580px;
  height: 540px;
  &__item {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 20px;
    border-radius: 30px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    z-index: 1;
    &--error {
      .box-drag-default__img {
        height: 270px;
        border: 8px solid $tryagain-color;
        box-sizing: border-box;
        border-radius: 20px;
        position: relative;
        &::before {
          position: absolute;
          content: "";
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            0deg,
            rgba(254, 99, 73, 0.5),
            rgba(254, 99, 73, 0.5)
          );
        }
      }
      .box-drag-default__title {
        color: $tryagain-color;
      }
    }
    .block-action {
      margin-top: auto;
    }
  }
  &__img {
    height: 434px;
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    img {
      visibility: hidden;
    }
  }
  &__title {
    font-family: "Filson Soft";
    font-weight: 800;
    font-size: 24px;
    line-height: calc(28 / 24);
    text-align: center;
    color: $title-black;
    margin-top: 20px;
  }
  &__note {
    font-family: "Roboto";
    font-size: 26px;
    line-height: calc(30 / 26);
    text-align: center;
    color: $tryagain-color;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
.box-result-item {
  position: relative;
  &__img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    img {
      visibility: hidden;
      max-height: 60vh;
    }
  }
}
.result-item {
  position: absolute;
  text-align: center;
  bottom: 10px;
  right: 70%;
  width: 200px;
  height: 160px;
  border: 4px solid #ffffff;
  box-sizing: border-box;
  border-radius: 20px;
  transform: rotate(-4deg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: rgba(0, 0, 0, 0.2);
  }
  span {
    font-family: "Filson Soft";
    font-weight: 800;
    font-size: 20px;
    line-height: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
  }
  &.result-item-v2 {
    right: -50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-4deg);
    span {
      width: 100%;
      padding: 20px;
      text-align: center;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.35);
    }
  }
}
.private-item {
  .result-item {
    right: unset;
    left: 70%;
    &.result-item-v2 {
      right: unset;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(-4deg);
      span {
        width: 100%;
        padding: 20px;
        text-align: center;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.35);
      }
    }
  }
}
.content-game-play {
  margin: auto;
}
.custom-modal-center {
  .modal-content {
    background: #fff;
    border: 4px solid $title-black;
    box-sizing: border-box;
    box-shadow: 0px 8px 0px #0a184f;
    border-radius: 30px;
    &__media {
      margin: auto;
      max-width: 272px;
      padding-top: 9px;
      margin-bottom: 25px;
      h4 {
        font-family: "Filson Soft";
        font-size: 18px;
        line-height: 1;
        color: $title-black;
        margin-top: 20px;
      }
    }
    &__description {
      font-family: "Roboto";
      font-size: 22px;
      line-height: calc(26 / 22);
      text-align: center;
      color: $title-black;
      max-width: 72%;
      margin: auto;
      margin-bottom: 15px;
      &--v2 {
        padding: 0 11%;
      }
    }
    &__list {
      display: flex;
      width: 80%;
      margin: auto;
      &--item {
        margin: 0 10px;
      }
    }
  }
  .modal-body {
    padding: 40px 40px 30px;
  }
}

.noti-correct {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  background: #fff;
  max-height: 100%;
  max-width: 100%;
  text-align: center;
  border-radius: 30px;
  overflow: auto;
  &__img {
    display: flex;
    align-content: center;
    justify-content: center;
    margin-bottom: 30px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    img {
      max-width: 160px;
      visibility: hidden;
      opacity: 0;
    }
  }
  &__title {
    font-size: 38px;
    line-height: 1;
    text-align: center;
    color: $title-black;
    margin-bottom: 30px;
    word-break: break-word;
  }
  &__subtitle {
    font-size: 24px;
    line-height: calc(28 / 24);
    margin-bottom: 20px;
    color: $title-black;
  }
  &__content {
    font-family: "Roboto";
    font-size: 26px;
    line-height: calc(30 / 26);
    text-align: center;
    color: $title-black;
    margin-bottom: 10px;
  }
}

.result-item-title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 15px);
  font-family: "Filson Soft";
  font-size: 20px;
  line-height: 1;
  color: #fff;
  width: 100%;
  text-align: center;
}

.avatar-clipper {
  clip-path: circle(40% at 50% 50%);
}

.opacity-check {
  opacity: 1;
  position: absolute;
  bottom: 9999px !important;
}
.non-opacity-check {
  opacity: 1;
  position: absolute;
  bottom: unset !important;
  z-index: 0;
}
@media (min-height: 200px) {
  .fullscreen.bg-game-mobile {
    background-position: center top;
  }
}

@media (min-height: 350px) {
  .custom-position-mobile {
    position: relative;
    min-height: 100vh;
    .fullscreen {
      min-height: 100vh;
    }
  }
  .box-drag-default {
    width: 45.6vw;
    height: 350px;
    &__img {
      height: 320px;
    }
  }
  .private-item {
    left: -125px;
  }
  .public-item {
    right: -115px;
  }
  .box-ufo-ready {
    margin-top: -80px;
  }
  .title-block-custom__top {
    margin-top: 20px;
  }
  .result-item {
    width: 160px;
    height: 130px;
  }
  .private-item.private-item-v2 .box-result-item__img,
  .public-item.public-item-v2 .box-result-item__img {
    width: 160px;
    height: 130px;
  }
  .title-block__full {
    font-size: 28px;
    line-height: 32px;
  }
  .choose-game__item {
    &--image {
      height: 180px;
      margin: auto;
      width: 50%;
    }

  }

}
@media (min-height: 500px) {
  .private-item {
    left: -125px;
  }
  .public-item {
    right: -115px;
  }
  .result-item {
    width: 160px;
    height: 130px;
  }
  .box-drag-default {
    width: 45.6vw;
    height: 400px;
    &__img {
      height: 320px;
    }
  }
  .fullscreen.bg-game-mobile {
    background-position: center bottom;
  }
}
@media (min-height: 575px) {
  .item-intro-slider {
    .image-introduction {
      max-height: 34vh;
      margin-bottom: 15px;
    }
    .content-introduction {
      font-size: 17px;
      margin-bottom: 5px;
    }
  }
  .custom-position-mobile {
    position: absolute;
    min-height: unset;
    .fullscreen {
      min-height: unset;
    }
  }
  .box-dot {
    margin-bottom: 10px;
  }
  .container-slider {
    max-width: 76%;
  }
}

@media (min-height: 650px) {
  .item-intro-slider .image-introduction {
    max-height: 37vh;
  }
}

@media (min-height: 715px) {
  .item-intro-slider .image-introduction {
    max-height: 37vh;
    margin-bottom: 30px;
  }
  .box-dot {
    margin-bottom: 30px;
  }
  .container-slider {
    max-width: 76%;
  }
  .private-item {
    left: -155px;
  }
  .public-item {
    right: -155px;
  }
  .result-item {
    width: 200px;
    height: 160px;
  }
  .box-drag-default {
    width: 45.6vw;
    height: 400px;
    &__img {
      height: 320px;
    }
  }
  .private-item.private-item-v2 .box-result-item__img,
  .public-item.public-item-v2 .box-result-item__img {
    width: 200px;
    height: 160px;
  }
  .noti-correct {
    &__img {
      margin-bottom: 15px;
      img {
        width: 105px;
        height: 105px;
      }
    }
    &__title {
      font-size: 28px;
      margin-bottom: 15px;
    }
    &__subtitle {
      font-size: 22px;
      margin-bottom: 10px;
    }
    &__content {
      font-size: 20px;
    }
  }
  .choose-game__item {
    &--image {
      height: 220px;
      width: 100%;
    }
    &--content {
      margin: 20px 0 16px;
    }
  }
  .box-ufo-ready {
    margin-top: -80px;
  }
  .title-block-custom__top {
    margin-top: 20px;
  }
}

@media (min-height: 770px) {
  .item-intro-slider {
    .content-introduction {
      font-size: 22px;
      margin-bottom: 26px;
    }
    .image-introduction {
      max-height: 42vh;
      margin-bottom: 30px;
    }
  }
  .box-dot {
    margin-bottom: 30px;
  }
  .box-drag-default {
    width: 48.6vw;
    height: 540px;
    &__img {
      height: 434px;
    }
  }
  .noti-correct {
    &__img {
      margin-bottom: 30px;
      pointer-events: none;
      img {
        width: 160px;
        height: 160px;
        pointer-events: none;
      }
    }
    &__title {
      font-size: 38px;
      margin-bottom: 30px;
    }
    &__subtitle {
      font-size: 24px;
      margin-bottom: 20px;
    }
    &__content {
      font-size: 26px;
    }
  }
  .choose-game__item {
    &--image {
      height: 280px;
    }
    &--content {
      margin: 40px 0 16px;
    }
  }
  .box-ufo-ready {
    margin-top: -35px;
  }
  .title-block-custom__top {
    margin-top: 60px;
  }
  .title-block__full {
    font-size: 38px;
    line-height: 1;
  }
}

@media (max-width: 1024px) {
  .noti-correct {
    &__img {
      margin-bottom: 15px;
      img {
        max-width: 105px;
      }
    }
    &__title {
      font-size: 28px;
      margin-bottom: 15px;
    }
    &__subtitle {
      font-size: 22px;
      margin-bottom: 10px;
    }
    &__content {
      font-size: 20px;
    }
  }
  .box-drag-default {
    &__note {
      font-size: 20px;
    }
  }
}

@media (min-width: 1366px) {
  .box-dot {
    max-width: calc(100% + 20px);
  }
}
